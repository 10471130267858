import React, { useEffect, useState } from 'react'
import { get, post } from 'aws-amplify/api';
import TextField from '@mui/material/TextField';


import logo from './logo.svg';
import './App.css';

function App() {
  const [acronym, setAcronym] = useState('FAANGMULA')
  const [companyList, setCompanies] = useState([])
  const [catchPhrase, setCatchphrase] = useState('...')



  async function fetchCompanies(acronym) {
    const acronymCall = get({
      apiName: "acronyms",
      path: `/companies/${acronym}`
    })
    const { body } = await acronymCall.response;

    const jzon = await body.json();
    console.log(jzon)
    setCompanies(jzon.companies);
  }


  async function fetchCatchPhrase(acronym) {
    const acronymCall = get({
      apiName: "acronyms",
      path: `/catchphrase/${acronym}`
    })
    const { body } = await acronymCall.response;

    const jzon = await body.json();
    console.log(jzon)
    setCatchphrase(jzon.catchphrase);
  }

  useEffect(() => {
    if (acronym.length == 0) return

    fetchCompanies(acronym)
    fetchCatchPhrase(acronym)
  }, [acronym])

  // useEffect(()=>{
  //   fetchCompanies(acronym)
  //   fetchCatchPhrase(acronym)
  // })

  async function handleInputChange(event) {
    const inputValue = event.target.value;

    // setAcronym(value);
      // Check if the input contains only letters from 'a' to 'z' (both cases)
      if (/^[a-zA-Z]*$/.test(inputValue)) {
        setAcronym(inputValue.toUpperCase());
      }
  }

  return (

     <header className="App-header">
     <div>
      <TextField
        label="Input Acronym"
        variant="outlined"
        fullWidth
        multiline
        value={acronym}
        onChange={handleInputChange}
        inputProps={{
          style: {
            fontSize: '48px', // Adjust the font size as needed
            color: 'rgb(0, 234, 255)',
            textTransform: 'uppercase', // Set text to uppercase
            textAlign: 'center',
            lineHeight: '1.5',
          borderColor: 'white',  // Set the outlined border color to white

            // height: '120px', // Adjust the height as needed
          },
        }}
        InputLabelProps={{
          style: {
            color: 'red', // Set label color to red (replace with your desired color)
          },
        }}
        style={{
          // color: 'white', // Set overall text color to white
          // borderColor: 'white', // Set the outlined border color to white
        }}
      />
    </div>

     <div>

     <ul className="aligned-list">
       {companyList.map((company, i) => <li key={i}>
        <span className="highlight-first-letter">{company}</span>

        </li>)}
     </ul>
     </div>

     <div className="App">
     <h2>{catchPhrase}</h2>
     
    </div>


   </header>
  );
}

export default App;
